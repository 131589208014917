<template>
  <div class="basic-table">
    <div class="row basic-table__header no-gutters align-items-center">
      <div class="col-4">
        <h3 class="basic-table__title text-seizeblack">
          입출금액 Top5
        </h3>
      </div>
      <div class="col-8 text-right">
        <a href="#/main_info/inout_top" target="_blank">
          <b-button
            :pressed="false"
            class="seize-base-margin seizeblack-button"
            variant="outline-seizeblack"
            >상세보기
          </b-button>
        </a>
      </div>
    </div>
    <div class="basic-table__table">
      <v-data-table
        :headers="headers"
        :items="seizeData"
        class="seize-table-box"
        dense
        hide-default-footer
        item-key="key"
        loading-text="데이터 조회중입니다..."
      >
        <template v-slot:item.date="{ item }">
          <span style="color: #000000; font-weight: bold;">{{
            item.date
          }}</span>
        </template>
        <template v-slot:item.status="{ item }">
          <span style="color: #000000;">{{ item.status }}</span>
        </template>
        <template v-slot:item.amount="{ item }">
          <span style="color: #000000;">{{ item.amount }}</span>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import numeral from "@/common/utils/numeral";
import SeizePlugin from "@/common/utils/seize";
import Vue from "vue";

Vue.use(SeizePlugin);
export default {
  data() {
    return {
      baseDate: this.$store.getters.baseDate,
      headers: [
        {
          text: "일자",
          sortable: false,
          align: "center",
          class: "seize-table-header seize-table-box",
          value: "date",
        },
        {
          text: "구분",
          sortable: false,
          align: "center",
          class: "seize-table-header seize-table-box",
          value: "status",
        },
        {
          text: "금액",
          sortable: false,
          align: "right",
          class: "seize-table-header seize-table-box",
          value: "amount",
        },
      ],
      seizeData: [],
    };
  },
  mounted() {
    this.baseDate = Vue.initBaseDate(this.baseDate);
    this.seizeData = this.generateInoutTop5Data();
  },
  methods: {
    updateView() {
      this.baseDate = this.$store.getters.baseDate;
      this.seizeData = this.generateInoutTop5Data();
    },
    generateInoutTop5Data() {
      const baseDate = this.baseDate;
      const before3M = Vue.getBeforeMonthString(baseDate, 3);
      const filteredList = this.$store.getters.requestItem.items.filter(
        function (value) {
          return (
            Vue.dateToDateString(value.tran_date) >= before3M &&
            Vue.dateToDateString(value.tran_date) <= baseDate
          );
        }
      );
      const sortedList = filteredList.sort(function (a, b) {
        return a.tran_amt > b.tran_amt ? -1 : a.tran_amt < b.tran_amt ? 1 : 0;
      });
      const MAX_COUNT = 5;
      const result = [];
      for (let i = 0; i < MAX_COUNT; i++) {
        if (sortedList[i]) {
          const data = {
            date: Vue.dateToDateString(sortedList[i].tran_date),
            status: sortedList[i].inout_type,
            amount: numeral(sortedList[i].tran_amt).format("0,0") + "원",
          };
          result.push(data);
        } else {
          break;
        }
      }
      return result;
    },
  },
};
</script>
