export default Object.freeze({
  INPUT_CODE: 1,
  OUTPUT_CODE: -1,
  BASE_DATE_DEFAULT: "2019-06-30",
  EXAMPLE_CHART_OFFICE_RANGE: [5.5, 10.74],
  EXAMPLE_CHART_OWNER_RANGE: [6.19, 14.72],
  EXAMPLE_CHART_FREELANCE_RANGE: [7.17, 12.41],
  EXAMPLE_CHART_HOUSEWIFE_RANGE: [7.17, 12.41],
  JOB_TYPE_LIST: ["-", "직장인", "자영업", "프리랜서", "주부", "기타"],
  SAMPLE_TYPE_LIST: ["직장인", "자영업", "기타"],
});
