<template>
  <div class="basic-table">
    <div
      class="basic-table__header"
      style="margin-bottom: 0; padding-bottom: 0;"
    >
      <h3 class="basic-table__title">
        월별 입출 현황
      </h3>
      <span class="basic-table__desc">
        월단위의 입금액,출금액을 표시합니다.
      </span>
    </div>
    <div class="basic-table__table">
      <Chart1
        ref="refInOutChart"
        height="80"
        v-bind:options="chartOptions"
        width="150"
      ></Chart1>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Chart1 from "@/views/partials/widgets/Chart.vue";
import numeral from "@/common/utils/numeral";
import * as d3 from "d3";
import Vue from "vue";

export default {
  name: "InOutChart",
  components: {
    Chart1,
  },
  data() {
    return {
      baseDate: this.$store.getters.baseDate,
      chartOptions: {},
    };
  },
  mounted() {
    this.baseDate = Vue.initBaseDate(this.baseDate);
    const rawData = this.generateInOutData();
    const chartData = {
      labels: this.makeChartLabel(rawData),
      datasets: this.makeChartData(rawData),
    };
    this.chartOptions = {
      type: "bar",
      data: chartData,
      options: {
        title: {
          display: true,
        },
        legend: {
          position: "bottom",
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItems, data) {
              const value = data.datasets[0].data[tooltipItems.index];
              return numeral(value).format("0,0") + "K";
            },
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: false,
                callback: function (value) {
                  return numeral(value).format("0,0") + "K";
                },
              },
            },
          ],
        },
      },
    };
  },
  methods: {
    updateView() {
      this.baseDate = this.$store.getters.baseDate;
      const rawData = this.generateInOutData();
      this.chartOptions.data.labels = this.makeChartLabel(rawData);
      this.chartOptions.data.datasets = this.makeChartData(rawData);
      this.$refs.refInOutChart.redrawChart();
    },
    generateInOutData() {
      let result = d3
        .nest()
        .key(function (data) {
          return Vue.dateToYearMonthString(data.tran_date);
        })
        .rollup(function (values) {
          return {
            input_sum: d3.sum(values, function (data) {
              if (data.inout_code === 1) {
                return data.tran_amt;
              } else {
                return 0;
              }
            }),
            output_sum: d3.sum(values, function (data) {
              if (data.inout_code === -1) {
                return data.tran_amt;
              } else {
                return 0;
              }
            }),
          };
        })
        .entries(this.$store.getters.requestItem.items);
      result.sort(function (a, b) {
        return a.key > b.key ? 1 : a.key < b.key ? -1 : 0;
      });
      return result;
    },
    makeChartLabel(rawData) {
      const result = [];
      rawData.forEach((element) => {
        result.push(Vue.dateToMonthString(element.key + "-01"));
      });
      return result;
    },
    makeChartData(rawData) {
      const result = [];
      const netProfit = {
        label: "입금총액",
        backgroundColor: "rgba(61, 157, 255, 1)",
      };
      const revenue = {
        label: "출금총액",
        backgroundColor: "rgba(27, 197, 189, 1)",
      };
      const freeCashFlow = {
        label: "잉여금액",
        backgroundColor: "rgba(255, 168, 0, 1)",
      };
      const netProfitData = [];
      const revenueData = [];
      const freeCashFlowData = [];
      rawData.forEach((element) => {
        netProfitData.push(Math.round(element.value.input_sum / 1000));
        revenueData.push(Math.round(element.value.output_sum / 1000));
        freeCashFlowData.push(
          Math.round(
            (element.value.input_sum - element.value.output_sum) / 1000
          )
        );
      });
      netProfit.data = netProfitData;
      revenue.data = revenueData;
      freeCashFlow.data = freeCashFlowData;
      result.push(netProfit);
      result.push(revenue);
      result.push(freeCashFlow);
      return result;
    },
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
};
</script>
