<template>
  <div class="basic-table">
    <div class="row basic-table__header no-gutters align-items-center">
      <div class="col-4">
        <h3 class="basic-table__title text-seizered">
          연체
        </h3>
      </div>
      <div class="col-8 text-right">
        <b-button
          :pressed="false"
          class="seize-base-margin seizered-button"
          variant="outline-seizered"
          @click.stop="dialog = true"
          >통계보기
        </b-button>
        <a href="#/main_info/over_due" target="_blank">
          <b-button
            :pressed="false"
            class="seize-base-margin seizered-button"
            variant="outline-seizered"
            >상세보기
          </b-button>
        </a>
      </div>
    </div>
    <div class="basic-table__table">
      <v-data-table
        :headers="headers"
        :items="seizeData"
        class="seize-table-box"
        dense
        hide-default-footer
        item-key="key"
        loading-text="데이터 조회중입니다..."
      >
        <template v-slot:item.key="{ item }">
          <span style="color: #000000; font-weight: bold;">{{ item.key }}</span>
        </template>
        <template v-slot:item.count="{ item }">
          <span style="color: #000000;">{{ item.count }}</span>
        </template>
      </v-data-table>
    </div>
    <v-dialog v-model="dialog" max-width="500">
      <chart v-bind="{ tableData: seizeData }"></chart>
    </v-dialog>
  </div>
</template>

<script>
import * as d3 from "d3";
import numeral from "@/common/utils/numeral";
import SeizePlugin from "@/common/utils/seize";
import Vue from "vue";
import ExampleChartOverdue from "@/views/partials/widgets/charts/ExampleChartOverdue";

Vue.use(SeizePlugin);
export default {
  components: { chart: ExampleChartOverdue },
  data() {
    return {
      baseDate: this.$store.getters.baseDate,
      headers: [
        {
          text: "기간",
          sortable: false,
          align: "center",
          class: "seize-table-header seize-table-box",
          value: "key",
        },
        {
          text: "건수",
          sortable: false,
          align: "center",
          class: "seize-table-header seize-table-box",
          value: "count",
        },
      ],
      seizeData: [],
      dialog: false,
    };
  },
  mounted() {
    this.baseDate = Vue.initBaseDate(this.baseDate);
    this.seizeData = this.generateOverdueData();
  },
  methods: {
    updateView() {
      this.baseDate = this.$store.getters.baseDate;
      this.seizeData = this.generateOverdueData();
    },
    generateOverdueData() {
      const categoryMap = d3
        .nest()
        .key(function (data) {
          return data.category_code1;
        })
        .map(this.$store.getters.requestItem.items);
      let categoryList = this.getOverdueCategoryData(categoryMap);
      const monthBaseData = d3
        .nest()
        .key(function (data) {
          return Vue.dateToDateString(data.tran_date);
        })
        .rollup(function (values) {
          return {
            count: d3.sum(values, function (data) {
              if (data.inout_code === -1) {
                if (data.after_balance_amt === 0) {
                  return 1;
                } else if (
                  data.category_code1 === "WDFISPI01060000" ||
                  data.category_code1 === "WDFIBNK00590090" ||
                  data.category_code1 === "WDFIBNK00590133"
                ) {
                  return 1;
                } else {
                  return 0;
                }
              } else {
                return 0;
              }
            }),
          };
        })
        .entries(categoryList);
      return this.getSimpleResult(monthBaseData);
    },
    getSimpleResult(values) {
      const result = [];
      const baseDate = this.baseDate;
      const before1M = Vue.getBeforeMonthString(baseDate, 1);
      const before2M = Vue.getBeforeMonthString(baseDate, 2);
      const before3M = Vue.getBeforeMonthString(baseDate, 3);
      let before1M_count = 0,
        before2M_count = 0,
        before3M_count = 0;
      values.forEach((element) => {
        if (element.key <= baseDate && element.key > before1M) {
          before1M_count += element.value.count;
        }
        if (element.key <= before1M && element.key > before2M) {
          before2M_count += element.value.count;
        }
        if (element.key <= before2M && element.key > before3M) {
          before3M_count += element.value.count;
        }
      });
      const b1MData = {
        key: "1M",
        count: numeral(before1M_count).format("0,0") + "건",
      };
      const b2MData = {
        key: "2M",
        count: numeral(before2M_count).format("0,0") + "건",
      };
      const b3MData = {
        key: "3M",
        count: numeral(before3M_count).format("0,0") + "건",
      };
      const sumData = {
        key: "합계",
        count:
          numeral(before1M_count + before2M_count + before3M_count).format(
            "0,0"
          ) + "건",
      };
      const avgData = {
        key: "평균",
        count:
          numeral(
            Math.round((before1M_count + before2M_count + before3M_count) / 3)
          ).format("0,0") + "건",
      };
      result.push(b1MData);
      result.push(b2MData);
      result.push(b3MData);
      result.push(sumData);
      result.push(avgData);
      return result;
    },
    getOverdueCategoryData(categoryMap) {
      let result = [];
      const codeList = [
        "WDLFUTB00340012",
        "WDLFUTB00340082",
        "WDLFUTB00340000",
        "WDLFUTB00610000",
        "WDLFUTB01180000",
        "WDLFUTB01760012",
        "WDLFUTB01760016",
        "WDLFUTB01760082",
        "WDLFUTB01760000",
        "WDLFUTB01910000",
        "WDFIMAD00510000",
        "WDFIMAD00590033",
        "WDFIMAD00590065",
        "WDFIMNL00590001",
        "WDFIMNL00590041",
        "WDFIMNL00590043",
        "WDFIMNL00770015",
        "WDLFRNT00000000",
        "WDFIINS00590049",
        "WDFIINS00590087",
        "WDFIINS00590000",
        "WDFIINS00770000",
        "WDFIINS00880000",
        "WDFIINS00880089",
        "WDFIINS00880101",
        "WDFIBNK00590047",
        "WDFIBNK00590049",
        "WDFIBNK00590080",
        "WDFIBNK00590090",
        "WDFIBNK00590097",
        "WDFIBNK00590133",
        "WDFIBNK00590000",
        "WDFISVB00000000",
        "WDFICAD00590105",
        "WDFICAD00590136",
        "WDFICAD01280000",
        "WDFICAD02410000",
        "WDFICPT00000000",
        "WDLFTEL00720037",
        "WDLFTEL00720052",
        "WDLFTEL00720057",
        "WDLFTEL00720063",
        "WDLFTEL00720064",
        "WDLFTEL00720085",
        "WDLFTEL01650063",
        "WDLFTEL01650038",
        "WDLFTEL01740063",
        "WDLFTEL01740064",
        "WDFISPI00140000",
        "WDFISPI00430000",
        "WDFISPI00540000",
        "WDFISPI00850000",
        "WDFISPI01000000",
        "WDFISPI01030000",
        "WDFISPI01050000",
        "WDFISPI01250000",
        "WDFISPI01260000",
        "WDFISPI01270000",
        "WDFISPI01290000",
        "WDFISPI01490000",
        "WDFISPI02060000",
        "WDFISPI02070000",
        "WDFISPI02520000",
        "WDFISPI02530000",
        "WDFISPI02540000",
        "WDFISPI01060000",
      ];
      codeList.forEach((element) => {
        if (categoryMap.get(element))
          result = result.concat(categoryMap.get(element));
      });
      return result;
    },
  },
};
</script>
