<template>
  <div class="basic-table" style="background-color: white;">
    <div class="basic-table__header kt-margin-b-30">
      <div class="d-flex align-items-center flex-row">
        <div class="container align-items-center text-center">
          <b-button
            :pressed.sync="officeMode"
            class="seize-base-margin seizeblue-button"
            variant="outline-seizeblue"
            @click="changeOfficeMode()"
            >직장인
          </b-button>
          <b-button
            :pressed.sync="ownerMode"
            class="seize-base-margin seizeblue-button"
            variant="outline-seizeblue"
            @click="changeOwnerMode()"
            >자영업
          </b-button>
          <b-button
            :pressed.sync="freelanceMode"
            class="seize-base-margin seizeblue-button"
            variant="outline-seizeblue"
            @click="changeFreelanceMode()"
            >프리랜서
          </b-button>
          <b-button
            :pressed.sync="housewifeMode"
            class="seize-base-margin seizeblue-button"
            variant="outline-seizeblue"
            @click="changeHousewifeMode()"
            >주부
          </b-button>
        </div>
      </div>
    </div>
    <div class="basic-table__table">
      <b-row>
        <b-col class="text-center">
          <h3 class="basic-table__title text-seizeblue">
            누적 3개월 연체 건수
          </h3>
          <canvas ref="count-chart"></canvas>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Chart from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import ChartDataLabels from "chartjs-plugin-datalabels";
import example from "@/common/mock/example.json";
// import numeral from "@/common/utils/numeral";
import Vue from "vue";
import * as d3 from "d3";
import numeral from "@/common/utils/numeral";
import constants from "@/common/utils/constants";

export default {
  name: "ExampleChartBalance",
  components: {},
  props: {
    tableData: {
      type: Array,
      default: function () {
        return [];
      },
    },
    baseDate: {
      type: String,
      default: function () {
        return Vue.dateToDateString(new Date());
      },
    },
  },
  data() {
    return {
      categoryName: "연체",
      officeMode: true,
      ownerMode: false,
      freelanceMode: false,
      housewifeMode: false,
      myLineChart: null,
      countChartData: [],
      countChartOptions: [],
      countChart: null,
      rawData: [],
      jobCategoryMap: [],
      countRateMax: 0,
      countAvg: 0,
      tableCount: 0,
    };
  },
  mounted() {
    this.baseDate = Vue.initBaseDate(this.baseDate);
    this.initValue();
    this.rawData = this.generateRawData();
    this.initPage();
  },
  methods: {
    initValue() {
      if (this.tableData.length !== 0) {
        this.tableCount = numeral(this.tableData[3].count).value();
      }
    },
    generateRawData() {
      const initialData = example.items.filter(
        (data) => data.info === this.categoryName
      );
      return d3
        .nest()
        .key(function (data) {
          return data.customer_group;
        })
        .map(initialData);
    },
    initPage() {
      let initializeJob = this.$store.getters.selectedJob;
      if (
        initializeJob !== "자영업" &&
        initializeJob !== "프리랜서" &&
        initializeJob !== "주부"
      ) {
        initializeJob = "직장인";
      }
      this.changeJobCategory(initializeJob);
      this.countChartData = {
        labels: this.makeChartCountLabel(),
        datasets: this.makeChartCountData(),
      };
      this.countChartOptions = {
        type: "bar",
        data: this.countChartData,
        plugins: [ChartDataLabels, annotationPlugin],
        options: {
          title: {
            display: false,
          },
          plugins: {
            datalabels: {
              anchor: "end",
              align: "end",
              color: "black",
              offset: 0,
              formatter: function (value) {
                return numeral(value).format("0,0") + "%";
              },
            },
          },
          annotation: {
            drawTime: "afterDatasetsDraw",
            annotations: [
              {
                drawTime: "afterDatasetsDraw",
                id: "avgLine",
                type: "line",
                mode: "horizontal",
                scaleID: "badRate",
                value: this.countAvg,
                borderColor: "rgba(246,78,96, 1)",
                borderWidth: 2,
                label: {
                  backgroundColor: "rgba(246,78,96, 1)",
                  content: "평균:" + this.countAvg,
                  enabled: true,
                },
              },
            ],
          },
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                gridLines: {
                  display: false,
                },
              },
            ],
            yAxes: [
              {
                id: "badRate",
                display: false,
                ticks: {
                  display: false,
                  beginAtZero: false,
                  suggestedMin: constants.EXAMPLE_CHART_OFFICE_RANGE[0],
                  suggestedMax: constants.EXAMPLE_CHART_OFFICE_RANGE[1],
                  callbacks: function (value) {
                    return value + "%";
                  },
                },
              },
              {
                id: "Rate",
                display: false,
                ticks: {
                  display: false,
                  beginAtZero: true,
                  suggestedMin: 0,
                  suggestedMax: this.countRateMax + 10,
                  callbacks: function (value) {
                    return value + "%";
                  },
                },
              },
            ],
          },
          tooltips: {
            callbacks: {
              label: function (tooltipItems) {
                if (tooltipItems.datasetIndex === 1) {
                  return (
                    "구성비 : " +
                    numeral(tooltipItems.value).format("0,0.00") +
                    "%"
                  );
                } else {
                  return (
                    "불량율 : " +
                    numeral(tooltipItems.value).format("0,0.00") +
                    "%"
                  );
                }
              },
            },
          },
        },
      };
      this.countChart = this.drawCountChart();
    },
    changeJobCategory(jobCategoryName) {
      if (jobCategoryName === "직장인") {
        this.officeMode = true;
        this.ownerMode = false;
        this.freelanceMode = false;
        this.housewifeMode = false;
      } else if (jobCategoryName === "자영업") {
        this.officeMode = false;
        this.ownerMode = true;
        this.freelanceMode = false;
        this.housewifeMode = false;
      } else if (jobCategoryName === "프리랜서") {
        this.officeMode = false;
        this.ownerMode = false;
        this.freelanceMode = true;
        this.housewifeMode = false;
        jobCategoryName = "프리랜서,주부";
      } else {
        this.officeMode = false;
        this.ownerMode = false;
        this.freelanceMode = false;
        this.housewifeMode = true;
        jobCategoryName = "프리랜서,주부";
      }
      const jobCategoryList = this.rawData.get(jobCategoryName);
      jobCategoryList.sort(function (a, b) {
        return a.bin_seq > b.bin_seq ? 1 : a.bin_seq < b.bin_seq ? -1 : 0;
      });
      this.jobCategoryMap = d3
        .nest()
        .key(function (data) {
          return data.variable;
        })
        .map(jobCategoryList);
    },
    makeChartCountLabel() {
      const result = [];
      this.jobCategoryMap.get("건수").forEach((element) => {
        result.push(element.bin_label);
      });
      return result;
    },
    makeChartCountData() {
      const result = [];
      const baseRateBarColor = "rgba(225,240,255, 1)";
      const hoverRateBarColor = "rgba(51,152,255, 1)";
      const rateData = {
        type: "bar",
        label: "구성비",
        backgroundColor: [],
        yAxisID: "Rate",
        barThickness: 60,
        maxBarThickness: 65,
        datalabels: {
          display: true,
        },
        borderColor: [],
        hoverBackgroundColor: hoverRateBarColor,
        hoverBorderColor: hoverRateBarColor,
        data: [],
      };
      const badRateData = {
        type: "line",
        label: "불량율",
        yAxisID: "badRate",
        datalabels: {
          display: false,
        },
        backgroundColor: "rgba(255,163,172, 1)",
        borderColor: "rgba(255,163,172, 1)",
        fill: false,
        data: [],
      };
      this.jobCategoryMap.get("건수").forEach((element) => {
        if (this.countRateMax < this.getPercentValue(element.total_ratio)) {
          this.countRateMax = this.getPercentValue(element.total_ratio);
        }
        rateData.data.push(this.getPercentValue(element.total_ratio));
        if (
          element.bin_start <= this.tableCount &&
          element.bin_end >= this.tableCount
        ) {
          rateData.backgroundColor.push(hoverRateBarColor);
          rateData.borderColor.push(hoverRateBarColor);
        } else {
          rateData.backgroundColor.push(baseRateBarColor);
          rateData.borderColor.push(baseRateBarColor);
        }
        this.countAvg = this.getPercentValue(element.total_bad_rate);
        badRateData.data.push(this.getPercentValue(element.bad_rate));
      });

      result.push(badRateData);
      result.push(rateData);
      return result;
    },
    drawCountChart() {
      const ctx = this.$refs["count-chart"].getContext("2d");
      const gradient = ctx.createLinearGradient(0, 0, 0, 240);
      gradient.addColorStop(
        0,
        Chart.helpers.color("#e14c86").alpha(1).rgbString()
      );
      gradient.addColorStop(
        1,
        Chart.helpers.color("#e14c86").alpha(0.3).rgbString()
      );
      return new Chart(ctx, this.countChartOptions);
    },

    getPercentValue(value) {
      return value * 100;
    },

    changeOfficeMode() {
      this.changeJobCategory("직장인");
      this.updateChartData("직장인");
    },
    changeOwnerMode() {
      this.changeJobCategory("자영업");
      this.updateChartData("자영업");
    },
    changeFreelanceMode() {
      this.changeJobCategory("프리랜서");
      this.updateChartData("프리랜서");
    },
    changeHousewifeMode() {
      this.changeJobCategory("주부");
      this.updateChartData("주부");
    },
    updateChartData(jobCategoryName) {
      this.countChartData.labels = this.makeChartCountLabel();
      this.countChartData.datasets = this.makeChartCountData();
      this.countChartOptions.data = this.countChartData;

      this.countChart.chartOptions = this.countChartOptions;
      this.countChart.annotation.elements[
        "avgLine"
      ].options.value = this.countAvg;
      this.countChart.annotation.elements["avgLine"].options.label.content =
        "평균:" + this.countAvg;
      this.countChart.options.scales.yAxes[1].ticks.suggestedMax =
        this.countRateMax + 10;
      if (jobCategoryName === "직장인") {
        this.countChart.options.scales.yAxes[0].ticks.suggestedMin =
          constants.EXAMPLE_CHART_OFFICE_RANGE[0];
        this.countChart.options.scales.yAxes[0].ticks.suggestedMax =
          constants.EXAMPLE_CHART_OFFICE_RANGE[1];
      } else if (jobCategoryName === "자영업") {
        this.countChart.options.scales.yAxes[0].ticks.suggestedMin =
          constants.EXAMPLE_CHART_OWNER_RANGE[0];
        this.countChart.options.scales.yAxes[0].ticks.suggestedMax =
          constants.EXAMPLE_CHART_OWNER_RANGE[1];
      } else if (jobCategoryName === "프리랜서") {
        this.countChart.options.scales.yAxes[0].ticks.suggestedMin =
          constants.EXAMPLE_CHART_FREELANCE_RANGE[0];
        this.countChart.options.scales.yAxes[0].ticks.suggestedMax =
          constants.EXAMPLE_CHART_FREELANCE_RANGE[1];
      } else {
        this.countChart.options.scales.yAxes[0].ticks.suggestedMin =
          constants.EXAMPLE_CHART_HOUSEWIFE_RANGE[0];
        this.countChart.options.scales.yAxes[0].ticks.suggestedMax =
          constants.EXAMPLE_CHART_HOUSEWIFE_RANGE[1];
      }
      this.countChart.update();
    },
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
};
</script>
