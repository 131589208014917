<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-2 order-xl-1">
        <KTPortlet v-bind:class="'kt-portlet--height-fluid'">
          <template v-slot:body>
            <BankTable ref="bankTable"></BankTable>
          </template>
        </KTPortlet>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-6 col-lg-6 order-lg-2 order-xl-1">
        <KTPortlet
          v-bind="{ bodyFit: true }"
          v-bind:class="'kt-portlet--height-fluid'"
        >
          <template v-slot:body>
            <BalanceChart ref="balanceChart"></BalanceChart>
          </template>
        </KTPortlet>
      </div>
      <div class="col-xl-6 col-lg-6 order-lg-2 order-xl-1">
        <KTPortlet
          v-bind="{ bodyFit: true }"
          v-bind:class="'kt-portlet--height-fluid'"
        >
          <template v-slot:body>
            <InOutChart ref="inoutChart"></InOutChart>
          </template>
        </KTPortlet>
      </div>
    </div>
    <div class="row">
      <div class="col-6 col-xl-4 col-lg-4 order-lg-2 order-xl-1">
        <KTPortlet
          v-bind="{ bodyFit: true }"
          v-bind:class="'kt-portlet--height-fluid'"
        >
          <template v-slot:body>
            <SalaryTable ref="salaryTable"></SalaryTable>
          </template>
        </KTPortlet>
      </div>
      <div class="col-6 col-xl-4 col-lg-4 order-lg-2 order-xl-1">
        <KTPortlet
          v-bind="{ bodyFit: true }"
          v-bind:class="'kt-portlet--height-fluid'"
        >
          <template v-slot:body>
            <SalesTable ref="salesTable"></SalesTable>
          </template>
        </KTPortlet>
      </div>
      <div class="col-6 col-xl-4 col-lg-4 order-lg-2 order-xl-1">
        <KTPortlet
          v-bind="{ bodyFit: true }"
          v-bind:class="'kt-portlet--height-fluid'"
        >
          <template v-slot:body>
            <EtcIncomeTable ref="etcIncomeTable"></EtcIncomeTable>
          </template>
        </KTPortlet>
      </div>
      <div class="col-6 col-xl-4 col-lg-4 order-lg-2 order-xl-1">
        <KTPortlet
          v-bind="{ bodyFit: true }"
          v-bind:class="'kt-portlet--height-fluid'"
        >
          <template v-slot:body>
            <BalanceTable ref="balanceTable"></BalanceTable>
          </template>
        </KTPortlet>
      </div>
      <div class="col-6 col-xl-4 col-lg-4 order-lg-2 order-xl-1">
        <KTPortlet
          v-bind="{ bodyFit: true }"
          v-bind:class="'kt-portlet--height-fluid'"
        >
          <template v-slot:body>
            <OverdueTable ref="overdueTable"></OverdueTable>
          </template>
        </KTPortlet>
      </div>
      <div class="col-6 col-xl-4 col-lg-4 order-lg-2 order-xl-1">
        <KTPortlet
          v-bind="{ bodyFit: true }"
          v-bind:class="'kt-portlet--height-fluid'"
        >
          <template v-slot:body>
            <InOutTop5Table ref="inOutTop5Table"></InOutTop5Table>
          </template>
        </KTPortlet>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-6 col-lg-6 order-lg-2 order-xl-1">
        <KTPortlet
          v-bind="{ bodyFit: true }"
          v-bind:class="'kt-portlet--height-fluid'"
        >
          <template v-slot:body>
            <InsuranceChart ref="insuranceChart"></InsuranceChart>
          </template>
        </KTPortlet>
      </div>
      <div class="col-xl-6 col-lg-6 order-lg-2 order-xl-1">
        <KTPortlet
          v-bind="{ bodyFit: true }"
          v-bind:class="'kt-portlet--height-fluid'"
        >
          <template v-slot:body>
            <SavingsChart ref="savingsChart"></SavingsChart>
          </template>
        </KTPortlet>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-6 col-lg-6 order-lg-2 order-xl-1">
        <KTPortlet
          v-bind="{ bodyFit: true }"
          v-bind:class="'kt-portlet--height-fluid'"
        >
          <template v-slot:body>
            <LoanChart ref="loanChart"></LoanChart>
          </template>
        </KTPortlet>
      </div>
      <div class="col-xl-6 col-lg-6 order-lg-2 order-xl-1">
        <KTPortlet
          v-bind="{ bodyFit: true }"
          v-bind:class="'kt-portlet--height-fluid'"
        >
          <template v-slot:body>
            <CardsFeeChart ref="cardsFeeChart"></CardsFeeChart>
          </template>
        </KTPortlet>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-6 col-lg-6 order-lg-2 order-xl-1">
        <KTPortlet
          v-bind="{ bodyFit: true }"
          v-bind:class="'kt-portlet--height-fluid'"
        >
          <template v-slot:body>
            <CommunicationCostChart
              ref="communicationCostChart"
            ></CommunicationCostChart>
          </template>
        </KTPortlet>
      </div>
      <div class="col-xl-6 col-lg-6 order-lg-2 order-xl-1">
        <KTPortlet
          v-bind="{ bodyFit: true }"
          v-bind:class="'kt-portlet--height-fluid'"
        >
          <template v-slot:body>
            <DuesChart ref="duesChart"></DuesChart>
          </template>
        </KTPortlet>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-6 col-lg-6 order-lg-2 order-xl-1">
        <KTPortlet
          v-bind="{ bodyFit: true }"
          v-bind:class="'kt-portlet--height-fluid'"
        >
          <template v-slot:body>
            <RentalsFeeChart ref="rentalsFeeChart"></RentalsFeeChart>
          </template>
        </KTPortlet>
      </div>
      <div class="col-xl-6 col-lg-6 order-lg-2 order-xl-1">
        <KTPortlet
          v-bind="{ bodyFit: true }"
          v-bind:class="'kt-portlet--height-fluid'"
        >
          <template v-slot:body>
            <DeathFeeChart ref="deathFeeChart"></DeathFeeChart>
          </template>
        </KTPortlet>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-6 col-lg-6 order-lg-2 order-xl-1">
        <KTPortlet
          v-bind="{ bodyFit: true }"
          v-bind:class="'kt-portlet--height-fluid'"
        >
          <template v-slot:body>
            <TaxChart ref="taxChart"></TaxChart>
          </template>
        </KTPortlet>
      </div>
    </div>
    <InsuranceReport
      ref="insuranceReport"
      v-bind="{ isHaveLink: true }"
    ></InsuranceReport>
    <BankReport ref="bankReport" v-bind="{ isHaveLink: true }"></BankReport>
    <TransferReport
      ref="transferReport"
      v-bind="{ isHaveLink: true }"
    ></TransferReport>
    <CardReport ref="cardReport" v-bind="{ isHaveLink: true }"></CardReport>
    <LoanReport ref="loanReport" v-bind="{ isHaveLink: true }"></LoanReport>
    <StockReport ref="stockReport" v-bind="{ isHaveLink: true }"></StockReport>
    <EtcReport ref="etcReport" v-bind="{ isHaveLink: true }"></EtcReport>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import BankTable from "@/views/partials/widgets/tables/BankTable.vue";
import BalanceChart from "@/views/partials/widgets/charts/BalanceChart.vue";
import InOutChart from "@/views/partials/widgets/charts/InOutChart";
import SalaryTable from "@/views/partials/widgets/tables/SalaryTable";
import SalesTable from "@/views/partials/widgets/tables/SalesTable";
import EtcIncomeTable from "@/views/partials/widgets/tables/EtcIncomeTable";
import BalanceTable from "@/views/partials/widgets/tables/BalanceTable";
import OverdueTable from "@/views/partials/widgets/tables/OverdueTable";
import InOutTop5Table from "@/views/partials/widgets/tables/InOutTop5Table";
import InsuranceChart from "@/views/partials/widgets/charts/InsuranceChart";
import SavingsChart from "@/views/partials/widgets/charts/SavingsChart";
import LoanChart from "@/views/partials/widgets/charts/LoanChart";
import CardsFeeChart from "@/views/partials/widgets/charts/CardsFeeChart";
import CommunicationCostChart from "@/views/partials/widgets/charts/CommunicationCostChart";
import DuesChart from "@/views/partials/widgets/charts/DuesChart";
import RentalsFeeChart from "@/views/partials/widgets/charts/RentalsFeeChart";
import DeathFeeChart from "@/views/partials/widgets/charts/DeathFeeChart";
import TaxChart from "@/views/partials/widgets/charts/TaxChart";
import InsuranceReport from "@/views/partials/widgets/reports/InsuranceReport";
import BankReport from "@/views/partials/widgets/reports/BankReport";
import TransferReport from "@/views/partials/widgets/reports/TransferReport";
import CardReport from "@/views/partials/widgets/reports/CardReport";
import LoanReport from "@/views/partials/widgets/reports/LoanReport";
import StockReport from "@/views/partials/widgets/reports/StockReport";
import EtcReport from "@/views/partials/widgets/reports/EtcReport";

export default {
  name: "dashboard",
  components: {
    KTPortlet,
    BankTable,
    BalanceChart,
    InOutChart,
    SalaryTable,
    SalesTable,
    EtcIncomeTable,
    BalanceTable,
    OverdueTable,
    InOutTop5Table,
    InsuranceChart,
    SavingsChart,
    LoanChart,
    CardsFeeChart,
    CommunicationCostChart,
    DuesChart,
    DeathFeeChart,
    RentalsFeeChart,
    TaxChart,
    InsuranceReport,
    BankReport,
    TransferReport,
    CardReport,
    LoanReport,
    StockReport,
    EtcReport,
  },
  data() {
    return {
      baseDate: this.$store.getters.baseDate,
      tabIndex: 0,
      tabIndex2: 0,
    };
  },
  mounted() {
    document.title = "대시보드";
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
  },
  methods: {
    updateView() {
      this.$refs.bankTable.updateView();
      this.$refs.balanceChart.updateView();
      this.$refs.inoutChart.updateView();
      this.$refs.salaryTable.updateView();
      this.$refs.salesTable.updateView();
      this.$refs.etcIncomeTable.updateView();
      this.$refs.balanceTable.updateView();
      this.$refs.overdueTable.updateView();
      this.$refs.inOutTop5Table.updateView();
      this.$refs.insuranceChart.updateView();
      this.$refs.savingsChart.updateView();
      this.$refs.loanChart.updateView();
      this.$refs.cardsFeeChart.updateView();
      this.$refs.communicationCostChart.updateView();
      this.$refs.duesChart.updateView();
      this.$refs.deathFeeChart.updateView();
      this.$refs.rentalsFeeChart.updateView();
      this.$refs.taxChart.updateView();
      this.$refs.insuranceReport.updateView();
      this.$refs.bankReport.updateView();
      this.$refs.transferReport.updateView();
      this.$refs.cardReport.updateView();
      this.$refs.loanReport.updateView();
      this.$refs.stockReport.updateView();
      this.$refs.etcReport.updateView();
    },
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },
  },
};
</script>
