<template>
  <div class="basic-table">
    <div class="basic-table__header">
      <div class="d-flex align-items-center flex-row">
        <div class="p-2">
          <h3 class="basic-table__title text-seizered">
            대출상환 이력
          </h3>
        </div>
        <div class="p-2 col text-right">
          <b-button
            :pressed.sync="valueMode"
            class="seizered-button"
            variant="outline-seizered"
            v-on:click="initValueMode"
            >금액
          </b-button>
          <b-button
            :pressed.sync="countMode"
            class="seizered-button"
            style="margin-right: 3px;"
            variant="outline-seizered"
            v-on:click="initCountMode"
            >건수
          </b-button>
          <b-button
            :pressed="false"
            class="seize-base-margin seizered-button"
            variant="outline-seizered"
            @click.stop="dialog = true"
            >통계보기
          </b-button>
          <a href="#/pay_info/loan" target="_blank">
            <b-button
              :pressed="false"
              class="seize-base-margin seizered-button"
              variant="outline-seizered"
              >상세보기
            </b-button>
          </a>
        </div>
      </div>
    </div>
    <div class="basic-table__table">
      <canvas ref="chart" height="120"></canvas>
    </div>
    <v-dialog v-model="dialog" max-width="1000">
      <chart
        v-bind="{ tableData: modalData, categoryName: '대출상환' }"
      ></chart>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Chart from "chart.js";
import numeral from "@/common/utils/numeral";
import * as d3 from "d3";
import Vue from "vue";
import DataController from "@/common/utils/data";
import ExampleChart from "@/views/partials/widgets/charts/ExampleChart";

export default {
  name: "LoanChart",
  components: { chart: ExampleChart },
  data() {
    return {
      baseDate: this.$store.getters.baseDate,
      countMode: false,
      valueMode: true,
      myLineChart: null,
      chartData: [],
      chartOptions: [],
      rawData: [],
      modalData: [],
      valueMax: 0,
      countMax: 0,
      dialog: false,
    };
  },
  mounted() {
    this.baseDate = Vue.initBaseDate(this.baseDate);
    this.rawData = this.generateRawData();
    this.modalData = this.getModalData();
    this.initPage();
  },
  methods: {
    updateView() {
      this.baseDate = this.$store.getters.baseDate;
      this.rawData = this.generateRawData();
      if (this.countMode) {
        this.initCountMode();
      } else {
        this.initValueMode();
      }
      this.myLineChart.update();
    },
    initPage() {
      this.countMode = false;
      this.valueMode = true;
      this.chartData = {
        labels: this.makeChartLabel(this.rawData),
        datasets: this.makeChartValueData(this.rawData),
      };
      this.chartOptions = {
        type: "line",
        data: this.chartData,
        options: {
          title: {
            display: false,
          },
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                gridLines: {
                  display: false,
                },
              },
            ],
            yAxes: [
              {
                display: false,
                ticks: {
                  suggestedMin: 0,
                  suggestedMax: this.valueMax + 10000,
                },
              },
            ],
          },
          tooltips: {
            callbacks: {
              label: function (tooltipItems, data) {
                const value = data.datasets[0].data[tooltipItems.index];
                return "금액 : " + numeral(value).format("0,0") + "원";
              },
            },
          },
        },
      };
      this.myLineChart = this.drawChart(this.chartOptions);
    },
    initCountMode() {
      this.countMode = true;
      this.valueMode = false;
      this.chartData.datasets = this.makeChartCountData(this.rawData);
      this.chartOptions.options.scales.yAxes = [
        {
          display: false,
          ticks: {
            suggestedMin: 0,
            suggestedMax: this.countMax + 5,
          },
        },
      ];
      this.chartOptions.options.tooltips.callbacks = {
        label: function (tooltipItems, data) {
          const value = data.datasets[0].data[tooltipItems.index];
          return "건수 : " + numeral(value).format("0,0") + "건";
        },
      };
      this.myLineChart.update();
    },
    initValueMode() {
      this.countMode = false;
      this.valueMode = true;
      this.chartData.datasets = this.makeChartValueData(this.rawData);
      this.chartOptions.options.scales.yAxes = [
        {
          display: false,
          ticks: {
            suggestedMin: 0,
            suggestedMax: this.valueMax + 10000,
          },
        },
      ];
      this.chartOptions.options.tooltips.callbacks = {
        label: function (tooltipItems, data) {
          const value = data.datasets[0].data[tooltipItems.index];
          return "금액 : " + numeral(value).format("0,0") + "원";
        },
      };
      this.myLineChart.update();
    },
    generateRawData() {
      const dataController = new DataController();
      dataController.setPayInfoData(
        this.baseDate,
        this.$store.getters.requestItem.items,
        "대출상환"
      );
      let categoryList = dataController.rawData;
      const baseDate = this.baseDate;
      const before6M = Vue.getBefore6MonthString(baseDate);
      const filteredList = categoryList.filter(function (value) {
        return (
          Vue.dateToDateString(value.tran_date) >= before6M &&
          Vue.dateToDateString(value.tran_date) <= baseDate
        );
      });
      const monthBaseData = d3
        .nest()
        .key(function (data) {
          return Vue.dateToYearMonthString(data.tran_date);
        })
        .rollup(function (values) {
          return {
            count: d3.sum(values, function (data) {
              if (data.inout_code === -1) {
                return 1;
              } else {
                return 0;
              }
            }),
            sum: d3.sum(values, function (data) {
              if (data.inout_code === -1) {
                return data.tran_amt;
              } else {
                return 0;
              }
            }),
          };
        })
        .entries(filteredList);
      this.makeEmptyData(monthBaseData, before6M, baseDate);
      monthBaseData.sort(function (a, b) {
        return a.key > b.key ? 1 : a.key < b.key ? -1 : 0;
      });
      return monthBaseData;
    },
    makeEmptyData(monthBaseData, startDate, endDate) {
      const keyExists = function (key) {
        return monthBaseData.some(function (element) {
          return element.key === key;
        });
      };
      while (startDate < endDate) {
        if (!keyExists(Vue.dateToYearMonthString(startDate))) {
          monthBaseData.push({
            key: Vue.dateToYearMonthString(startDate),
            value: { count: 0, sum: 0 },
          });
        }
        startDate = Vue.getAfterMonthString(startDate, 1);
      }
    },
    makeChartLabel(rawData) {
      const result = [];
      rawData.forEach((element) => {
        result.push(Vue.dateToMonthString(element.key + "-01"));
      });
      return result;
    },
    makeChartCountData(rawData) {
      const result = [];
      const data = {
        label: "대출상환",
        backgroundColor: "rgba(255,226,229, 1)",
        borderColor: "rgba(246,78,96, 1)",
        fill: "start",
      };
      const value = [];
      rawData.forEach((element) => {
        value.push(element.value.count);
        if (this.countMax < element.value.count)
          this.countMax = element.value.count;
      });
      data.data = value;
      result.push(data);
      return result;
    },
    makeChartValueData(rawData) {
      const result = [];
      const data = {
        label: "대출상환",
        backgroundColor: "rgba(255,226,229, 1)",
        borderColor: "rgba(246,78,96, 1)",
        fill: "start",
      };
      const value = [];
      rawData.forEach((element) => {
        value.push(element.value.sum);
        if (this.valueMax < element.value.sum)
          this.valueMax = element.value.sum;
      });
      data.data = value;
      result.push(data);
      return result;
    },
    drawChart(chartOptions) {
      const ctx = this.$refs["chart"].getContext("2d");
      const gradient = ctx.createLinearGradient(0, 0, 0, 240);
      gradient.addColorStop(
        0,
        Chart.helpers.color("#e14c86").alpha(1).rgbString()
      );
      gradient.addColorStop(
        1,
        Chart.helpers.color("#e14c86").alpha(0.3).rgbString()
      );
      return new Chart(ctx, chartOptions);
    },
    updateChart(chartOptions) {
      this.myLineChart.chartOptions = chartOptions;
      this.myLineChart.update();
    },
    getModalData() {
      const result = [];
      const baseDate = this.baseDate;
      const before1M = Vue.getBeforeMonthString(baseDate, 1);
      const before2M = Vue.getBeforeMonthString(baseDate, 2);
      const before3M = Vue.getBeforeMonthString(baseDate, 3);
      let before1M_count = 0,
        before1M_value = 0,
        before2M_count = 0,
        before2M_value = 0,
        before3M_count = 0,
        before3M_value = 0;
      this.rawData.forEach((element) => {
        if (element.key <= baseDate && element.key > before1M) {
          before1M_count += element.value.count;
          before1M_value += element.value.sum;
        }
        if (element.key <= before1M && element.key > before2M) {
          before2M_count += element.value.count;
          before2M_value += element.value.sum;
        }
        if (element.key <= before2M && element.key > before3M) {
          before3M_count += element.value.count;
          before3M_value += element.value.sum;
        }
      });
      const b1MData = {
        key: "1M",
        count: numeral(before1M_count).format("0,0") + "건",
        sum: numeral(before1M_value).format("0,0") + "원",
      };
      const b2MData = {
        key: "2M",
        count: numeral(before2M_count).format("0,0") + "건",
        sum: numeral(before2M_value).format("0,0") + "원",
      };
      const b3MData = {
        key: "3M",
        count: numeral(before3M_count).format("0,0") + "건",
        sum: numeral(before3M_value).format("0,0") + "원",
      };
      const sumData = {
        key: "합계",
        count:
          numeral(before1M_count + before2M_count + before3M_count).format(
            "0,0"
          ) + "건",
        sum:
          numeral(before1M_value + before2M_value + before3M_value).format(
            "0,0"
          ) + "원",
      };
      const avgData = {
        key: "평균",
        count:
          numeral(
            Math.round((before1M_count + before2M_count + before3M_count) / 3)
          ).format("0,0") + "건",
        sum:
          before1M_count + before2M_count + before3M_count === 0
            ? "0원"
            : numeral(
                Math.round(
                  (before1M_value + before2M_value + before3M_value) /
                    (before1M_count + before2M_count + before3M_count)
                )
              ).format("0,0") + "원",
      };
      result.push(b1MData);
      result.push(b2MData);
      result.push(b3MData);
      result.push(sumData);
      result.push(avgData);
      return result;
    },
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
};
</script>
