<template>
  <div class="basic-table">
    <div class="row basic-table__header kt-margin-b-30">
      <div class="col-8">
        <h3 class="basic-table__title">
          통장 개요 정보
        </h3>
        <span class="basic-table__desc">
          제출한 통장에 대한 개요 정보를 표시합니다.
        </span>
      </div>
      <div class="col-4 text-right">
        <a href="#/filter/transaction_history" target="_blank">
          <b-button
            :pressed="false"
            class="seize-base-margin seizegreen-button"
            variant="outline-seizegreen"
            >거래내역 상세보기
          </b-button>
        </a>
      </div>
    </div>
    <div class="basic-table__table">
      <v-data-table
        :headers="headers"
        :items="seizeData"
        class="elevation-1"
        hide-default-footer
        item-key="key"
        loading-text="데이터 조회중입니다..."
        multi-sort
        show-expand
      >
        <template v-slot:expanded-item="{ headers, item }">
          <td colspan="2"></td>
          <td colspan="7">
            <v-data-table
              :headers="sub_headers"
              :items="item.values"
              class="elevation-10"
              hide-default-footer
              item-key="key"
              multi-sort
            >
            </v-data-table>
          </td>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import * as d3 from "d3";
import numeral from "@/common/utils/numeral";
import SeizePlugin from "@/common/utils/seize";
import Vue from "vue";

Vue.use(SeizePlugin);
export default {
  data() {
    return {
      baseDate: this.$store.getters.baseDate,
      singleSelect: false,
      singleExpand: false,
      expanded: ["key"],
      headers: [
        {
          text: "은행명",
          align: "center",
          width: "15%",
          class: "seize-table-header",
          value: "key",
        },
        {
          text: "계좌수",
          align: "center",
          width: "10%",
          class: "seize-table-header",
          value: "values.length",
        },
        {
          text: "시작일",
          align: "center",
          width: "12%",
          class: "seize-table-header",
          value: "total_min_date",
        },
        {
          text: "종료일",
          align: "center",
          width: "12%",
          class: "seize-table-header",
          value: "total_max_date",
        },
        {
          text: "입금액",
          align: "right",
          width: "12%",
          class: "seize-table-header",
          value: "total_input_sum",
          sort: (a, b) => numeral(a).value() - numeral(b).value(),
        },
        {
          text: "출금액",
          align: "right",
          width: "12%",
          class: "seize-table-header",
          value: "total_output_sum",
          sort: (a, b) => numeral(a).value() - numeral(b).value(),
        },
        {
          text: "입금수",
          align: "right",
          width: "10%",
          class: "seize-table-header",
          value: "total_input_count",
          sort: (a, b) => numeral(a).value() - numeral(b).value(),
        },
        {
          text: "출금수",
          align: "right",
          width: "10%",
          class: "seize-table-header",
          value: "total_output_count",
          sort: (a, b) => numeral(a).value() - numeral(b).value(),
        },
      ],
      sub_headers: [
        {
          text: "계좌번호",
          align: "center",
          class: "seize-table-header",
          value: "key",
        },
        {
          text: "시작일",
          align: "center",
          class: "seize-table-header",
          value: "value.min_date",
        },
        {
          text: "종료일",
          align: "center",
          class: "seize-table-header",
          value: "value.max_date",
        },
        {
          text: "입금액",
          class: "seize-table-header",
          align: "right",
          value: "value.input_sum",
          sort: (a, b) => numeral(a).value() - numeral(b).value(),
        },
        {
          text: "출금액",
          class: "seize-table-header",
          align: "right",
          value: "value.output_sum",
          sort: (a, b) => numeral(a).value() - numeral(b).value(),
        },
        {
          text: "입금수",
          class: "seize-table-header",
          align: "right",
          value: "value.input_count",
          sort: (a, b) => numeral(a).value() - numeral(b).value(),
        },
        {
          text: "출금수",
          class: "seize-table-header",
          align: "right",
          value: "value.output_count",
          sort: (a, b) => numeral(a).value() - numeral(b).value(),
        },
      ],
      seizeData: [],
    };
  },
  mounted() {
    this.baseDate = Vue.initBaseDate(this.baseDate);
    this.seizeData = this.generateBankData();
  },
  methods: {
    updateView() {
      this.baseDate = this.$store.getters.baseDate;
      this.seizeData = this.generateBankData();
    },
    generateBankData() {
      const result = d3
        .nest()
        .key(function (data) {
          return data.bank_name;
        })
        .key(function (data) {
          return data.account_num;
        })
        .rollup(function (values) {
          return {
            count: values.length,
            min_date: d3.min(values, function (data) {
              return Vue.dateToDateString(data.tran_date);
            }),
            max_date: d3.max(values, function (data) {
              return Vue.dateToDateString(data.tran_date);
            }),
            input_count: numeral(
              d3.sum(values, function (data) {
                if (data.inout_code === 1) {
                  return 1;
                } else {
                  return 0;
                }
              })
            ).format("0,0"),
            input_sum: numeral(
              d3.sum(values, function (data) {
                if (data.inout_code === 1) {
                  return data.tran_amt;
                } else {
                  return 0;
                }
              })
            ).format("0,0"),
            output_count: numeral(
              d3.sum(values, function (data) {
                if (data.inout_code === -1) {
                  return 1;
                } else {
                  return 0;
                }
              })
            ).format("0,0"),
            output_sum: numeral(
              d3.sum(values, function (data) {
                if (data.inout_code === -1) {
                  return data.tran_amt;
                } else {
                  return 0;
                }
              })
            ).format("0,0"),
          };
        })
        .entries(this.$store.getters.requestItem.items);
      result.forEach((element) => {
        element["total_min_date"] = this.getMinDate(element.values);
        element["total_max_date"] = this.getMaxDate(element.values);
        element["total_input_sum"] = this.getTotalInputSum(element.values);
        element["total_output_sum"] = this.getTotalOutputSum(element.values);
        element["total_input_count"] = this.getTotalInputCount(element.values);
        element["total_output_count"] = this.getTotalOutputCount(
          element.values
        );
      });
      return result;
    },
    getMinDate(values) {
      return d3.min(values, function (data) {
        return data.value.min_date;
      });
    },
    getMaxDate(values) {
      return d3.max(values, function (data) {
        return data.value.max_date;
      });
    },
    getTotalInputSum(values) {
      return numeral(
        d3.sum(values, function (data) {
          return numeral(data.value.input_sum).value();
        })
      ).format("0,0");
    },
    getTotalInputCount(values) {
      return numeral(
        d3.sum(values, function (data) {
          return numeral(data.value.input_count).value();
        })
      ).format("0,0");
    },
    getTotalOutputSum(values) {
      return numeral(
        d3.sum(values, function (data) {
          return numeral(data.value.output_sum).value();
        })
      ).format("0,0");
    },
    getTotalOutputCount(values) {
      return numeral(
        d3.sum(values, function (data) {
          return numeral(data.value.output_count).value();
        })
      ).format("0,0");
    },
  },
};
</script>
