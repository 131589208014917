<template>
  <div class="row col-xl-12 col-lg-12 order-lg-2 order-xl-1">
    <div class="col-6" style="padding: 0;">
      <KTReportPortlet
        v-bind="{ bodyFit: true }"
        v-bind:class="'kt-portlet--height-fluid theme-seize-red'"
      >
        <template v-slot:body>
          <div class="basic-table">
            <div class="basic-table__header">
              <div class="d-flex align-items-center flex-row">
                <div class="col-6">
                  <h3 class="basic-table__title__white">
                    캐피탈/저축/대부업권 거래
                  </h3>
                </div>
                <div class="col-6 text-right">
                  <b-button
                    :pressed.sync="valueMode"
                    class="outline-seizered-reverse-button"
                    variant="outline-seizered"
                    v-on:click="initValueMode"
                    >금액
                  </b-button>
                  <b-button
                    :pressed.sync="countMode"
                    class="outline-seizered-reverse-button"
                    variant="outline-seizered"
                    v-on:click="initCountMode"
                    >건수
                  </b-button>
                </div>
              </div>
            </div>
            <div class="basic-table__table row">
              <div class="col-4">
                <canvas ref="equity-chart" height="200"></canvas>
              </div>
              <div class="col-4">
                <canvas ref="liability-chart" height="200"></canvas>
              </div>
              <div class="col-4">
                <canvas ref="etc-chart" height="200"></canvas>
              </div>
            </div>
          </div>
        </template>
      </KTReportPortlet>
    </div>
    <div class="col-6" style="padding: 0;">
      <KTPortlet
        v-bind="{ bodyFit: true }"
        v-bind:class="'kt-portlet--height-fluid'"
      >
        <template v-slot:body>
          <div class="row no-gutters">
            <div class="col-6" style="padding: 0;">
              <ReportOutputTable
                v-bind="{
                  titleColorClassString: 'text-seizered',
                  outputData: outputTableData,
                  isHaveLink: isHaveLink,
                  titleButtonLink: '#/inout_info/loan',
                  titleButtonVariant: 'outline-seizered',
                  titleButtonClass: 'seizered-button',
                }"
              >
              </ReportOutputTable>
            </div>
            <div class="col-6" style="padding: 0;">
              <ReportInputTable
                v-bind="{
                  titleColorClassString: 'text-seizered',
                  inputData: inputTableData,
                  isHaveLink: isHaveLink,
                  titleButtonLink: '#/inout_info/loan',
                  titleButtonVariant: 'outline-seizered',
                  titleButtonClass: 'seizered-button',
                }"
              >
              </ReportInputTable>
            </div>
          </div>
        </template>
      </KTPortlet>
    </div>
  </div>
</template>

<script>
import KTReportPortlet from "@/views/partials/content/ReportPortlet.vue";
import KTPortlet from "@/views/partials/content/Portlet.vue";

import ReportOutputTable from "@/views/partials/widgets/tables/ReportOutputTable";
import ReportInputTable from "@/views/partials/widgets/tables/ReportInputTable";
import * as d3 from "d3";
import numeral from "@/common/utils/numeral";
import SeizePlugin from "@/common/utils/seize";
import Vue from "vue";
import Chart from "chart.js";

Vue.use(SeizePlugin);
export default {
  name: "LoanReport",
  components: {
    KTReportPortlet,
    KTPortlet,
    ReportOutputTable,
    ReportInputTable,
  },
  data() {
    return {
      baseDate: this.$store.getters.baseDate,
      countMode: false,
      valueMode: true,
      inputTableData: [],
      outputTableData: [],
      chartCountMax: Number.MIN_SAFE_INTEGER,
      chartValueMax: Number.MIN_SAFE_INTEGER,
    };
  },
  props: {
    isHaveLink: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
  },
  mounted() {
    this.baseDate = Vue.initBaseDate(this.baseDate);
    this.initializeCategoryData();
    this.generateEquityData();
    this.generateLiabilityData();
    this.generateEtcData();
    this.initChart();
    this.generateTableData();
  },
  methods: {
    updateView() {
      this.baseDate = this.$store.getters.baseDate;
      this.initializeCategoryData();
      this.generateEquityData();
      this.generateLiabilityData();
      this.generateEtcData();
      if (this.countMode) {
        this.initCountMode();
      } else {
        this.initValueMode();
      }
      this.generateTableData();
    },
    initializeCategoryData() {
      this.categoryMap = d3
        .nest()
        .key(function (data) {
          return data.category_code1;
        })
        .map(this.$store.getters.requestItem.items);
    },
    initChart() {
      this.countMode = false;
      this.valueMode = true;
      this.equityChartData = {
        labels: this.makeChartLabel(),
        datasets: this.makeChartValueData(this.equityRawData),
      };
      this.equityChartOptions = {
        type: "bar",
        data: this.equityChartData,
        options: {
          maintainAspectRatio: false,
          title: {
            display: true,
            text: "캐피탈",
            position: "bottom",
            fontColor: "#fff",
          },
          legend: {
            display: false,
          },
          scales: {
            yAxes: [
              {
                display: true,
                gridLines: {
                  color: "transparent",
                  lineWidth: 0,
                  zeroLineColor: "rgba(255,255,255,1)",
                },
                ticks: {
                  display: false,
                  beginAtZero: true,
                  suggestedMax: this.chartValueMax,
                  callback: function (value) {
                    return numeral(value).format("0,0") + "원";
                  },
                },
              },
            ],
            xAxes: [
              {
                display: true,
                gridLines: {
                  display: false,
                },
                ticks: {
                  beginAtZero: true,
                  fontColor: "rgba(255,255,255,1)",
                },
              },
            ],
          },
          tooltips: {
            callbacks: {
              label: function (tooltipItems, data) {
                const labelText =
                  data.datasets[tooltipItems.datasetIndex].label;
                const value =
                  data.datasets[tooltipItems.datasetIndex].data[
                    tooltipItems.index
                  ];
                return labelText + " : " + numeral(value).format("0,0") + "원";
              },
            },
          },
        },
      };
      this.equityChart = this.drawChart(
        "equity-chart",
        this.equityChartOptions
      );
      this.liabilityChartData = {
        labels: this.makeChartLabel(),
        datasets: this.makeChartValueData(this.liabilityRawData),
      };
      this.liabilityChartOptions = {
        type: "bar",
        data: this.liabilityChartData,
        options: {
          maintainAspectRatio: false,
          title: {
            display: true,
            text: "저축은행",
            position: "bottom",
            fontColor: "#fff",
          },
          legend: {
            display: false,
          },
          scales: {
            yAxes: [
              {
                display: true,
                gridLines: {
                  color: "transparent",
                  lineWidth: 0,
                  zeroLineColor: "rgba(255,255,255,1)",
                },
                ticks: {
                  display: false,
                  beginAtZero: true,
                  suggestedMax: this.chartValueMax,
                  callback: function (value) {
                    return numeral(value).format("0,0") + "원";
                  },
                },
              },
            ],
            xAxes: [
              {
                display: true,
                gridLines: {
                  display: false,
                },
                ticks: {
                  beginAtZero: true,
                  fontColor: "rgba(255,255,255,1)",
                },
              },
            ],
          },
          tooltips: {
            callbacks: {
              label: function (tooltipItems, data) {
                const labelText =
                  data.datasets[tooltipItems.datasetIndex].label;
                const value =
                  data.datasets[tooltipItems.datasetIndex].data[
                    tooltipItems.index
                  ];
                return labelText + " : " + numeral(value).format("0,0") + "원";
              },
            },
          },
        },
      };
      this.liabilityChart = this.drawChart(
        "liability-chart",
        this.liabilityChartOptions
      );
      this.etcChartData = {
        labels: this.makeChartLabel(),
        datasets: this.makeChartValueData(this.etcRawData),
      };
      this.etcChartOptions = {
        type: "bar",
        data: this.etcChartData,
        options: {
          maintainAspectRatio: false,
          title: {
            display: true,
            text: "대부",
            position: "bottom",
            fontColor: "#fff",
          },
          legend: {
            display: false,
          },
          scales: {
            yAxes: [
              {
                display: true,
                gridLines: {
                  color: "transparent",
                  lineWidth: 0,
                  zeroLineColor: "rgba(255,255,255,1)",
                },
                ticks: {
                  display: false,
                  beginAtZero: true,
                  suggestedMax: this.chartValueMax,
                  callback: function (value) {
                    return numeral(value).format("0,0") + "원";
                  },
                },
              },
            ],
            xAxes: [
              {
                display: true,
                gridLines: {
                  display: false,
                },
                ticks: {
                  beginAtZero: true,
                  fontColor: "rgba(255,255,255,1)",
                },
              },
            ],
          },
          tooltips: {
            callbacks: {
              label: function (tooltipItems, data) {
                const labelText =
                  data.datasets[tooltipItems.datasetIndex].label;
                const value =
                  data.datasets[tooltipItems.datasetIndex].data[
                    tooltipItems.index
                  ];
                return labelText + " : " + numeral(value).format("0,0") + "원";
              },
            },
          },
        },
      };
      this.etcChart = this.drawChart("etc-chart", this.etcChartOptions);
    },
    initCountMode() {
      this.countMode = true;
      this.valueMode = false;
      this.equityChartData.datasets = this.makeChartCountData(
        this.equityRawData
      );
      this.equityChartOptions.options.scales.yAxes = [
        {
          display: true,
          gridLines: {
            color: "transparent",
            lineWidth: 0,
            zeroLineColor: "rgba(255,255,255,1)",
          },
          ticks: {
            display: false,
            beginAtZero: true,
            suggestedMax: this.chartCountMax,
            callback: function (value) {
              return numeral(value).format("0,0") + "건";
            },
          },
        },
      ];
      this.equityChartOptions.options.tooltips.callbacks = {
        label: function (tooltipItems, data) {
          const labelText = data.datasets[tooltipItems.datasetIndex].label;
          const value =
            data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index];
          return labelText + " : " + numeral(value).format("0,0") + "건";
        },
      };
      this.equityChart.update();

      this.liabilityChartData.datasets = this.makeChartCountData(
        this.liabilityRawData
      );
      this.liabilityChartOptions.options.scales.yAxes = [
        {
          display: true,
          gridLines: {
            color: "transparent",
            lineWidth: 0,
            zeroLineColor: "rgba(255,255,255,1)",
          },
          ticks: {
            display: false,
            beginAtZero: true,
            suggestedMax: this.chartCountMax,
            callback: function (value) {
              return numeral(value).format("0,0") + "건";
            },
          },
        },
      ];
      this.liabilityChartOptions.options.tooltips.callbacks = {
        label: function (tooltipItems, data) {
          const labelText = data.datasets[tooltipItems.datasetIndex].label;
          const value =
            data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index];
          return labelText + " : " + numeral(value).format("0,0") + "건";
        },
      };
      this.liabilityChart.update();

      this.etcChartData.datasets = this.makeChartCountData(this.etcRawData);
      this.etcChartOptions.options.scales.yAxes = [
        {
          display: true,
          gridLines: {
            color: "transparent",
            lineWidth: 0,
            zeroLineColor: "rgba(255,255,255,1)",
          },
          ticks: {
            display: false,
            beginAtZero: true,
            suggestedMax: this.chartCountMax,
            callback: function (value) {
              return numeral(value).format("0,0") + "건";
            },
          },
        },
      ];
      this.etcChartOptions.options.tooltips.callbacks = {
        label: function (tooltipItems, data) {
          const labelText = data.datasets[tooltipItems.datasetIndex].label;
          const value =
            data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index];
          return labelText + " : " + numeral(value).format("0,0") + "건";
        },
      };
      this.etcChart.update();
    },
    initValueMode() {
      this.countMode = false;
      this.valueMode = true;
      this.equityChartData.datasets = this.makeChartValueData(
        this.equityRawData
      );
      this.equityChartOptions.options.scales.yAxes = [
        {
          display: true,
          gridLines: {
            color: "transparent",
            lineWidth: 0,
            zeroLineColor: "rgba(255,255,255,1)",
          },
          ticks: {
            display: false,
            beginAtZero: true,
            suggestedMax: this.chartValueMax,
            callback: function (value) {
              return numeral(value).format("0,0") + "원";
            },
          },
        },
      ];
      this.equityChartOptions.options.tooltips.callbacks = {
        label: function (tooltipItems, data) {
          const labelText = data.datasets[tooltipItems.datasetIndex].label;
          const value =
            data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index];
          return labelText + " : " + numeral(value).format("0,0") + "원";
        },
      };
      this.equityChart.update();

      this.liabilityChartData.datasets = this.makeChartValueData(
        this.liabilityRawData
      );
      this.liabilityChartOptions.options.scales.yAxes = [
        {
          display: true,
          gridLines: {
            color: "transparent",
            lineWidth: 0,
            zeroLineColor: "rgba(255,255,255,1)",
          },
          ticks: {
            display: false,
            beginAtZero: true,
            suggestedMax: this.chartValueMax,
            callback: function (value) {
              return numeral(value).format("0,0") + "원";
            },
          },
        },
      ];
      this.liabilityChartOptions.options.tooltips.callbacks = {
        label: function (tooltipItems, data) {
          const labelText = data.datasets[tooltipItems.datasetIndex].label;
          const value =
            data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index];
          return labelText + " : " + numeral(value).format("0,0") + "원";
        },
      };
      this.liabilityChart.update();

      this.etcChartData.datasets = this.makeChartValueData(this.etcRawData);
      this.etcChartOptions.options.scales.yAxes = [
        {
          display: true,
          gridLines: {
            color: "transparent",
            lineWidth: 0,
            zeroLineColor: "rgba(255,255,255,1)",
          },
          ticks: {
            display: false,
            beginAtZero: true,
            suggestedMax: this.chartValueMax,
            callback: function (value) {
              return numeral(value).format("0,0") + "원";
            },
          },
        },
      ];
      this.etcChartOptions.options.tooltips.callbacks = {
        label: function (tooltipItems, data) {
          const labelText = data.datasets[tooltipItems.datasetIndex].label;
          const value =
            data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index];
          return labelText + " : " + numeral(value).format("0,0") + "원";
        },
      };
      this.etcChart.update();
    },
    makeChartLabel() {
      const result = ["3M", "2M", "1M"];
      return result;
    },
    makeChartValueData(rawData) {
      const result = [];
      const netProfit = {
        label: "입금금액",
        backgroundColor: "rgba(255, 255, 255, 1)",
      };
      const revenue = {
        label: "출금금액",
        backgroundColor: "rgba(255, 163, 172, 1)",
      };
      const netProfitData = [];
      const revenueData = [];
      rawData.input.forEach((element) => {
        netProfitData.push(Math.round(element.sum));
      });
      rawData.output.forEach((element) => {
        revenueData.push(Math.round(element.sum));
      });

      netProfit.data = netProfitData.reverse();
      revenue.data = revenueData.reverse();
      result.push(revenue);
      result.push(netProfit);
      return result;
    },
    makeChartCountData(rawData) {
      const result = [];
      const netProfit = {
        label: "입금수",
        backgroundColor: "rgba(255, 255, 255, 1)",
      };
      const revenue = {
        label: "출금수",
        backgroundColor: "rgba(255, 163, 172, 1)",
      };
      const netProfitData = [];
      const revenueData = [];
      rawData.input.forEach((element) => {
        netProfitData.push(Math.round(element.count));
      });
      rawData.output.forEach((element) => {
        revenueData.push(Math.round(element.count));
      });

      netProfit.data = netProfitData.reverse();
      revenue.data = revenueData.reverse();
      result.push(revenue);
      result.push(netProfit);
      return result;
    },
    drawChart(refName, chartOptions) {
      const ctx = this.$refs[refName].getContext("2d");
      const gradient = ctx.createLinearGradient(0, 0, 0, 100);
      gradient.addColorStop(
        0,
        Chart.helpers.color("#e14c86").alpha(1).rgbString()
      );
      gradient.addColorStop(
        1,
        Chart.helpers.color("#e14c86").alpha(0.3).rgbString()
      );
      return new Chart(ctx, chartOptions);
    },
    generateEquityData() {
      let equityList = this.getEquityCategoryData(this.categoryMap);
      const monthBaseInputData = d3
        .nest()
        .key(function (data) {
          return Vue.dateToDateString(data.tran_date);
        })
        .rollup(function (values) {
          return {
            count: d3.sum(values, function (data) {
              if (data.inout_code === 1) {
                return 1;
              } else {
                return 0;
              }
            }),
            sum: d3.sum(values, function (data) {
              if (data.inout_code === 1) {
                return data.tran_amt;
              } else {
                return 0;
              }
            }),
          };
        })
        .entries(equityList);
      const monthBaseOutputData = d3
        .nest()
        .key(function (data) {
          return Vue.dateToDateString(data.tran_date);
        })
        .rollup(function (values) {
          return {
            count: d3.sum(values, function (data) {
              if (data.inout_code === -1) {
                return 1;
              } else {
                return 0;
              }
            }),
            sum: d3.sum(values, function (data) {
              if (data.inout_code === -1) {
                return data.tran_amt;
              } else {
                return 0;
              }
            }),
          };
        })
        .entries(equityList);
      this.equityRawData = this.getSimpleChartResult(
        monthBaseInputData,
        monthBaseOutputData
      );
    },
    generateLiabilityData() {
      let equityList = this.getLiabilityCategoryData(this.categoryMap);
      const monthBaseInputData = d3
        .nest()
        .key(function (data) {
          return Vue.dateToDateString(data.tran_date);
        })
        .rollup(function (values) {
          return {
            count: d3.sum(values, function (data) {
              if (data.inout_code === 1) {
                return 1;
              } else {
                return 0;
              }
            }),
            sum: d3.sum(values, function (data) {
              if (data.inout_code === 1) {
                return data.tran_amt;
              } else {
                return 0;
              }
            }),
          };
        })
        .entries(equityList);
      const monthBaseOutputData = d3
        .nest()
        .key(function (data) {
          return Vue.dateToDateString(data.tran_date);
        })
        .rollup(function (values) {
          return {
            count: d3.sum(values, function (data) {
              if (data.inout_code === -1) {
                return 1;
              } else {
                return 0;
              }
            }),
            sum: d3.sum(values, function (data) {
              if (data.inout_code === -1) {
                return data.tran_amt;
              } else {
                return 0;
              }
            }),
          };
        })
        .entries(equityList);
      this.liabilityRawData = this.getSimpleChartResult(
        monthBaseInputData,
        monthBaseOutputData
      );
    },
    generateEtcData() {
      let equityList = this.getEtcCategoryData(this.categoryMap);
      const monthBaseInputData = d3
        .nest()
        .key(function (data) {
          return Vue.dateToDateString(data.tran_date);
        })
        .rollup(function (values) {
          return {
            count: d3.sum(values, function (data) {
              if (data.inout_code === 1) {
                return 1;
              } else {
                return 0;
              }
            }),
            sum: d3.sum(values, function (data) {
              if (data.inout_code === 1) {
                return data.tran_amt;
              } else {
                return 0;
              }
            }),
          };
        })
        .entries(equityList);
      const monthBaseOutputData = d3
        .nest()
        .key(function (data) {
          return Vue.dateToDateString(data.tran_date);
        })
        .rollup(function (values) {
          return {
            count: d3.sum(values, function (data) {
              if (data.inout_code === -1) {
                return 1;
              } else {
                return 0;
              }
            }),
            sum: d3.sum(values, function (data) {
              if (data.inout_code === -1) {
                return data.tran_amt;
              } else {
                return 0;
              }
            }),
          };
        })
        .entries(equityList);
      this.etcRawData = this.getSimpleChartResult(
        monthBaseInputData,
        monthBaseOutputData
      );
    },
    generateTableData() {
      let salesList = this.getCategoryData(this.categoryMap);
      const monthBaseInputData = d3
        .nest()
        .key(function (data) {
          return Vue.dateToDateString(data.tran_date);
        })
        .rollup(function (values) {
          return {
            count: d3.sum(values, function (data) {
              if (data.inout_code === 1) {
                return 1;
              } else {
                return 0;
              }
            }),
            sum: d3.sum(values, function (data) {
              if (data.inout_code === 1) {
                return data.tran_amt;
              } else {
                return 0;
              }
            }),
          };
        })
        .entries(salesList);
      const monthBaseOutputData = d3
        .nest()
        .key(function (data) {
          return Vue.dateToDateString(data.tran_date);
        })
        .rollup(function (values) {
          return {
            count: d3.sum(values, function (data) {
              if (data.inout_code === -1) {
                return 1;
              } else {
                return 0;
              }
            }),
            sum: d3.sum(values, function (data) {
              if (data.inout_code === -1) {
                return data.tran_amt;
              } else {
                return 0;
              }
            }),
          };
        })
        .entries(salesList);
      this.inputTableData = this.getSimpleResult(monthBaseInputData);
      this.outputTableData = this.getSimpleResult(monthBaseOutputData);
    },
    getCategoryData(categoryMap) {
      let result = [];
      const codeList = [
        "DPFIMNL00590001",
        "DPFIMNL00590041",
        "DPFIMNL00590043",
        "DPFIMNL00770015",
        "WDFIMNL00590001",
        "WDFIMNL00590041",
        "WDFIMNL00590043",
        "WDFIMNL00770015",
        "DPFISVB00000000",
        "WDFISVB00000000",
        "DPFICPT00000000",
        "WDFICPT00000000",
      ];
      codeList.forEach((element) => {
        if (categoryMap.get(element))
          result = result.concat(categoryMap.get(element));
      });
      return result;
    },
    getEquityCategoryData(categoryMap) {
      let result = [];
      const codeList = ["DPFICPT00000000", "WDFICPT00000000"];
      codeList.forEach((element) => {
        if (categoryMap.get(element))
          result = result.concat(categoryMap.get(element));
      });
      return result;
    },
    getLiabilityCategoryData(categoryMap) {
      let result = [];
      const codeList = ["DPFISVB00000000", "WDFISVB00000000"];

      codeList.forEach((element) => {
        if (categoryMap.get(element))
          result = result.concat(categoryMap.get(element));
      });
      return result;
    },
    getEtcCategoryData(categoryMap) {
      let result = [];
      const codeList = [
        "DPFIMNL00590001",
        "DPFIMNL00590041",
        "DPFIMNL00590043",
        "DPFIMNL00770015",
        "WDFIMNL00590001",
        "WDFIMNL00590041",
        "WDFIMNL00590043",
        "WDFIMNL00770015",
      ];
      codeList.forEach((element) => {
        if (categoryMap.get(element))
          result = result.concat(categoryMap.get(element));
      });
      return result;
    },
    getSimpleResult(values) {
      const result = [];
      const baseDate = this.baseDate;
      const before1M = Vue.getBeforeMonthString(baseDate, 1);
      const before2M = Vue.getBeforeMonthString(baseDate, 2);
      const before3M = Vue.getBeforeMonthString(baseDate, 3);
      let before1M_count = 0,
        before1M_value = 0,
        before2M_count = 0,
        before2M_value = 0,
        before3M_count = 0,
        before3M_value = 0;
      values.forEach((element) => {
        if (element.key <= baseDate && element.key > before1M) {
          before1M_count += element.value.count;
          before1M_value += element.value.sum;
        }
        if (element.key <= before1M && element.key > before2M) {
          before2M_count += element.value.count;
          before2M_value += element.value.sum;
        }
        if (element.key <= before2M && element.key > before3M) {
          before3M_count += element.value.count;
          before3M_value += element.value.sum;
        }
      });
      const b1MData = {
        key: "1M",
        count: numeral(before1M_count).format("0,0") + "건",
        sum: numeral(before1M_value).format("0,0") + "원",
      };
      const b2MData = {
        key: "2M",
        count: numeral(before2M_count).format("0,0") + "건",
        sum: numeral(before2M_value).format("0,0") + "원",
      };
      const b3MData = {
        key: "3M",
        count: numeral(before3M_count).format("0,0") + "건",
        sum: numeral(before3M_value).format("0,0") + "원",
      };
      const sumData = {
        key: "합계",
        count:
          numeral(before1M_count + before2M_count + before3M_count).format(
            "0,0"
          ) + "건",
        sum:
          numeral(before1M_value + before2M_value + before3M_value).format(
            "0,0"
          ) + "원",
      };
      const avgData = {
        key: "평균",
        count:
          numeral(
            Math.round((before1M_count + before2M_count + before3M_count) / 3)
          ).format("0,0") + "건",
        sum:
          before1M_count + before2M_count + before3M_count === 0
            ? "0원"
            : numeral(
                Math.round(
                  (before1M_value + before2M_value + before3M_value) /
                    (before1M_count + before2M_count + before3M_count)
                )
              ).format("0,0") + "원",
      };
      result.push(b1MData);
      result.push(b2MData);
      result.push(b3MData);
      result.push(sumData);
      result.push(avgData);
      return result;
    },
    getSimpleChartResult(inputValues, outputValues) {
      const inputResult = [];
      const outputResult = [];
      const baseDate = this.baseDate;
      const before1M = Vue.getBeforeMonthString(baseDate, 1);
      const before2M = Vue.getBeforeMonthString(baseDate, 2);
      const before3M = Vue.getBeforeMonthString(baseDate, 3);
      let before1M_count = 0,
        before1M_value = 0,
        before2M_count = 0,
        before2M_value = 0,
        before3M_count = 0,
        before3M_value = 0;
      inputValues.forEach((element) => {
        if (this.chartValueMax < element.value.sum) {
          this.chartValueMax = element.value.sum;
        }
        if (this.chartCountMax < element.value.count) {
          this.chartCountMax = element.value.count;
        }
        if (element.key <= baseDate && element.key > before1M) {
          before1M_count += element.value.count;
          before1M_value += element.value.sum;
        }
        if (element.key <= before1M && element.key > before2M) {
          before2M_count += element.value.count;
          before2M_value += element.value.sum;
        }
        if (element.key <= before2M && element.key > before3M) {
          before3M_count += element.value.count;
          before3M_value += element.value.sum;
        }
      });
      let b1MData = {
        key: "1M",
        count: before1M_count,
        sum: before1M_value,
      };
      let b2MData = {
        key: "2M",
        count: before2M_count,
        sum: before2M_value,
      };
      let b3MData = {
        key: "3M",
        count: before3M_count,
        sum: before3M_value,
      };
      inputResult.push(b1MData);
      inputResult.push(b2MData);
      inputResult.push(b3MData);

      before1M_count = 0;
      before1M_value = 0;
      before2M_count = 0;
      before2M_value = 0;
      before3M_count = 0;
      before3M_value = 0;
      outputValues.forEach((element) => {
        if (this.chartValueMax < element.value.sum) {
          this.chartValueMax = element.value.sum;
        }
        if (this.chartCountMax < element.value.count) {
          this.chartCountMax = element.value.count;
        }
        if (element.key <= baseDate && element.key > before1M) {
          before1M_count += element.value.count;
          before1M_value += element.value.sum;
        }
        if (element.key <= before1M && element.key > before2M) {
          before2M_count += element.value.count;
          before2M_value += element.value.sum;
        }
        if (element.key <= before2M && element.key > before3M) {
          before3M_count += element.value.count;
          before3M_value += element.value.sum;
        }
      });
      b1MData = {
        key: "1M",
        count: before1M_count,
        sum: before1M_value,
      };
      b2MData = {
        key: "2M",
        count: before2M_count,
        sum: before2M_value,
      };
      b3MData = {
        key: "3M",
        count: before3M_count,
        sum: before3M_value,
      };
      outputResult.push(b1MData);
      outputResult.push(b2MData);
      outputResult.push(b3MData);
      return { input: inputResult, output: outputResult };
    },
  },
};
</script>

<style scoped></style>
