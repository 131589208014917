<template>
  <div class="basic-table">
    <div class="row basic-table__header no-gutters align-items-center">
      <div class="col-4">
        <h3 class="basic-table__title text-seizepurple">
          급여
        </h3>
      </div>
      <div class="col-8 text-right">
        <b-button
          :pressed="false"
          class="seize-base-margin seizepurple-button"
          variant="outline-seizepurple"
          @click.stop="dialog = true"
          >통계보기
        </b-button>
        <a href="#/main_info/salary" target="_blank">
          <b-button
            :pressed="false"
            class="seize-base-margin seizepurple-button"
            variant="outline-seizepurple"
            >상세보기
          </b-button>
        </a>
      </div>
    </div>
    <div class="basic-table__table">
      <v-data-table
        :headers="headers"
        :items="seizeData"
        class="seize-table-box"
        dense
        hide-default-footer
        item-key="key"
        loading-text="데이터 조회중입니다..."
      >
        <template v-slot:item.key="{ item }">
          <span style="color: #000000; font-weight: bold;">{{ item.key }}</span>
        </template>
        <template v-slot:item.count="{ item }">
          <span style="color: #000000;">{{ item.count }}</span>
        </template>
        <template v-slot:item.sum="{ item }">
          <span style="color: #000000;">{{ item.sum }}</span>
        </template>
      </v-data-table>
    </div>
    <v-dialog v-model="dialog" max-width="1000">
      <chart v-bind="{ tableData: seizeData, categoryName: '급여' }"></chart>
    </v-dialog>
  </div>
</template>

<script>
import * as d3 from "d3";
import numeral from "@/common/utils/numeral";
import SeizePlugin from "@/common/utils/seize";
import Vue from "vue";
import ExampleChart from "@/views/partials/widgets/charts/ExampleChart";

Vue.use(SeizePlugin);
export default {
  components: { chart: ExampleChart },
  data() {
    return {
      baseDate: this.$store.getters.baseDate,
      headers: [
        {
          text: "기간",
          sortable: false,
          align: "center",
          class: "seize-table-header seize-table-box",
          value: "key",
        },
        {
          text: "건수",
          sortable: false,
          align: "center",
          class: "seize-table-header seize-table-box",
          value: "count",
        },
        {
          text: "합계",
          sortable: false,
          align: "right",
          class: "seize-table-header seize-table-box",
          value: "sum",
        },
      ],
      seizeData: [],
      dialog: false,
    };
  },
  mounted() {
    this.baseDate = Vue.initBaseDate(this.baseDate);
    this.seizeData = this.generateSalaryData();
  },
  methods: {
    updateView() {
      this.baseDate = this.$store.getters.baseDate;
      this.seizeData = this.generateSalaryData();
    },
    generateSalaryData() {
      const categoryMap = d3
        .nest()
        .key(function (data) {
          return data.category_code1;
        })
        .map(this.$store.getters.requestItem.items);
      let salaryList = this.getSalaryCategoryData(categoryMap);

      const monthBaseData = d3
        .nest()
        .key(function (data) {
          return Vue.dateToDateString(data.tran_date);
        })
        .rollup(function (values) {
          return {
            count: d3.sum(values, function (data) {
              if (data.inout_code === 1) {
                return 1;
              } else {
                return 0;
              }
            }),
            sum: d3.sum(values, function (data) {
              if (data.inout_code === 1) {
                return data.tran_amt;
              } else {
                return 0;
              }
            }),
          };
        })
        .entries(salaryList);
      return this.getSimpleResult(monthBaseData);
    },
    getSimpleResult(values) {
      const result = [];
      const baseDate = this.baseDate;
      const before1M = Vue.getBeforeMonthString(baseDate, 1);
      const before2M = Vue.getBeforeMonthString(baseDate, 2);
      const before3M = Vue.getBeforeMonthString(baseDate, 3);
      let before1M_count = 0,
        before1M_value = 0,
        before2M_count = 0,
        before2M_value = 0,
        before3M_count = 0,
        before3M_value = 0;
      values.forEach((element) => {
        if (element.key <= baseDate && element.key > before1M) {
          before1M_count += element.value.count;
          before1M_value += element.value.sum;
        }
        if (element.key <= before1M && element.key > before2M) {
          before2M_count += element.value.count;
          before2M_value += element.value.sum;
        }
        if (element.key <= before2M && element.key > before3M) {
          before3M_count += element.value.count;
          before3M_value += element.value.sum;
        }
      });
      const b1MData = {
        key: "1M",
        count: numeral(before1M_count).format("0,0") + "건",
        sum: numeral(before1M_value).format("0,0") + "원",
      };
      const b2MData = {
        key: "2M",
        count: numeral(before2M_count).format("0,0") + "건",
        sum: numeral(before2M_value).format("0,0") + "원",
      };
      const b3MData = {
        key: "3M",
        count: numeral(before3M_count).format("0,0") + "건",
        sum: numeral(before3M_value).format("0,0") + "원",
      };
      const sumData = {
        key: "합계",
        count:
          numeral(before1M_count + before2M_count + before3M_count).format(
            "0,0"
          ) + "건",
        sum:
          numeral(before1M_value + before2M_value + before3M_value).format(
            "0,0"
          ) + "원",
      };
      const avgData = {
        key: "평균",
        count:
          numeral(
            Math.round((before1M_count + before2M_count + before3M_count) / 3)
          ).format("0,0") + "건",
        sum:
          before1M_count + before2M_count + before3M_count === 0
            ? "0원"
            : numeral(
                Math.round(
                  (before1M_value + before2M_value + before3M_value) /
                    (before1M_count + before2M_count + before3M_count)
                )
              ).format("0,0") + "원",
      };
      result.push(b1MData);
      result.push(b2MData);
      result.push(b3MData);
      result.push(sumData);
      result.push(avgData);
      return result;
    },
    getSalaryCategoryData(categoryMap) {
      let result = [];
      const codeList = [
        "DPICEIC00419102",
        "DPICEIC00419101",
        "DPICEIC00410000",
        "DPICEIC00770000",
      ];
      codeList.forEach((element) => {
        if (categoryMap.get(element))
          result = result.concat(categoryMap.get(element));
      });
      return result;
    },
  },
};
</script>
