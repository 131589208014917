<template>
  <div class="basic-table">
    <div class="row basic-table__header no-gutters align-items-center">
      <div class="col-4">
        <h3 class="basic-table__title text-seizeorange">
          잔액
        </h3>
      </div>
      <div class="col-8 text-right">
        <b-button
          :pressed="false"
          class="seize-base-margin seizeorange-button"
          variant="outline-seizeorange"
          @click.stop="dialog = true"
          >통계보기
        </b-button>
        <a href="#/main_info/balance" target="_blank">
          <b-button
            :pressed="false"
            class="seize-base-margin seizeorange-button"
            variant="outline-seizeorange"
            >상세보기
          </b-button>
        </a>
      </div>
    </div>
    <div class="basic-table__table">
      <v-data-table
        :headers="headers"
        :items="seizeData"
        class="seize-table-box"
        dense
        hide-default-footer
        item-key="key"
        loading-text="데이터 조회중입니다..."
      >
        <template v-slot:item.key="{ item }">
          <span style="color: #000000; font-weight: bold;">{{ item.key }}</span>
        </template>
        <template v-slot:item.count="{ item }">
          <span style="color: #000000;">{{ item.count }}</span>
        </template>
        <template v-slot:item.sum="{ item }">
          <span style="color: #000000;">{{ item.sum }}</span>
        </template>
      </v-data-table>
    </div>
    <v-dialog v-model="dialog" max-width="1000">
      <chart v-bind="{ tableData: seizeData }"></chart>
    </v-dialog>
  </div>
</template>

<script>
import * as d3 from "d3";
import numeral from "@/common/utils/numeral";
import SeizePlugin from "@/common/utils/seize";
import Vue from "vue";
import ExampleChartBalance from "@/views/partials/widgets/charts/ExampleChartBalance";

Vue.use(SeizePlugin);
export default {
  components: { chart: ExampleChartBalance },
  data() {
    return {
      baseDate: this.$store.getters.baseDate,
      headers: [
        {
          text: "기간",
          sortable: false,
          align: "center",
          class: "seize-table-header seize-table-box",
          value: "key",
        },
        {
          text: "0일수",
          sortable: false,
          align: "center",
          class: "seize-table-header seize-table-box",
          value: "count",
        },
        {
          text: "평잔",
          sortable: false,
          align: "right",
          class: "seize-table-header seize-table-box",
          value: "sum",
        },
      ],
      seizeData: [],
      dialog: false,
    };
  },
  mounted() {
    this.baseDate = Vue.initBaseDate(this.baseDate);
    this.seizeData = this.generateBalanceData();
  },
  methods: {
    updateView() {
      this.baseDate = this.$store.getters.baseDate;
      this.seizeData = this.generateBalanceData();
    },
    generateBalanceData() {
      const groupByBankList = d3
        .nest()
        .key(function (data) {
          return data.bank_name;
        })
        .key(function (data) {
          return data.account_num;
        })
        .entries(this.$store.getters.requestItem.items);
      const rawOHLC = Vue.makeBalanceOHLCList(groupByBankList);
      const OHLC = d3
        .nest()
        .key(function (data) {
          return data.date;
        })
        .rollup(function (values) {
          return {
            open: d3.sum(values, function (data) {
              return data.open;
            }),
            high: d3.sum(values, function (data) {
              return data.high;
            }),
            low: d3.sum(values, function (data) {
              return data.low;
            }),
            close: d3.sum(values, function (data) {
              return data.close;
            }),
          };
        })
        .entries(rawOHLC);
      return this.getSimpleResult(OHLC);
    },
    getSimpleResult(values) {
      const monthlyData = d3
        .nest()
        .key(function (data) {
          return Vue.dateToDateString(data.key);
        })
        .rollup(function (values) {
          return {
            count_0: d3.sum(values, function (data) {
              if (data.value.close === 0) {
                return 1;
              } else {
                return 0;
              }
            }),
            count_non_0: d3.sum(values, function (data) {
              if (data.value.close !== 0) {
                return 1;
              } else {
                return 0;
              }
            }),
            close_sum: d3.sum(values, function (data) {
              return data.value.close;
            }),
          };
        })
        .entries(values);
      const result = [];
      const baseDate = this.baseDate;
      const before1M = Vue.getBeforeMonthString(baseDate, 1);
      const before2M = Vue.getBeforeMonthString(baseDate, 2);
      const before3M = Vue.getBeforeMonthString(baseDate, 3);
      let before1M_count = 0,
        before1M_value = 0,
        before1M_day_count = 0,
        before2M_count = 0,
        before2M_value = 0,
        before2M_day_count = 0,
        before3M_count = 0,
        before3M_value = 0,
        before3M_day_count = 0;
      monthlyData.forEach((element) => {
        if (element.key <= baseDate && element.key > before1M) {
          before1M_count += element.value.count_0;
          before1M_day_count += element.value.count_non_0;
          before1M_day_count += element.value.count_0;
          before1M_value += element.value.close_sum;
        }
        if (element.key <= before1M && element.key > before2M) {
          before2M_count += element.value.count_0;
          before2M_day_count += element.value.count_non_0;
          before2M_day_count += element.value.count_0;
          before2M_value += element.value.close_sum;
        }
        if (element.key <= before2M && element.key > before3M) {
          before3M_count += element.value.count_0;
          before3M_day_count += element.value.count_non_0;
          before3M_day_count += element.value.count_0;
          before3M_value += element.value.close_sum;
        }
      });
      const b1MData = {
        key: "1M",
        count: numeral(before1M_count).format("0,0") + "일",
        sum:
          numeral(Math.round(before1M_value / before1M_day_count)).format(
            "0,0"
          ) + "원",
      };
      const b2MData = {
        key: "2M",
        count: numeral(before2M_count).format("0,0") + "일",
        sum:
          numeral(Math.round(before2M_value / before2M_day_count)).format(
            "0,0"
          ) + "원",
      };
      const b3MData = {
        key: "3M",
        count: numeral(before3M_count).format("0,0") + "일",
        sum:
          numeral(Math.round(before3M_value / before3M_day_count)).format(
            "0,0"
          ) + "원",
      };
      const sumData = {
        key: "합계",
        count:
          numeral(before1M_count + before2M_count + before3M_count).format(
            "0,0"
          ) + "일",
        sum:
          numeral(
            Math.round(before1M_value / before1M_day_count) +
              Math.round(before2M_value / before2M_day_count) +
              Math.round(before3M_value / before3M_day_count)
          ).format("0,0") + "원",
      };
      const avgData = {
        key: "평균",
        count:
          numeral(
            Math.round((before1M_count + before2M_count + before3M_count) / 3)
          ).format("0,0") + "일",
        sum:
          numeral(
            Math.round(
              (Math.round(before1M_value / before1M_day_count) +
                Math.round(before2M_value / before2M_day_count) +
                Math.round(before3M_value / before3M_day_count)) /
                3
            )
          ).format("0,0") + "원",
      };
      result.push(b1MData);
      result.push(b2MData);
      result.push(b3MData);
      result.push(sumData);
      result.push(avgData);
      return result;
    },
  },
};
</script>
