<template>
  <div class="basic-table">
    <div
      class="basic-table__header"
      style="margin-bottom: 0; padding-bottom: 0;"
    >
      <h3 class="basic-table__title">
        잔액 정보
      </h3>
      <span class="basic-table__desc">
        전체 계좌의 잔액 정보를 표시합니다.
      </span>
    </div>
    <div class="basic-table__table">
      <div ref="chartdiv" class="hello"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_material from "@amcharts/amcharts4/themes/material";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as d3 from "d3";
import Vue from "vue";

am4core.useTheme(am4themes_material);
am4core.useTheme(am4themes_animated);

export default {
  name: "BalanceChart",
  components: {},
  data() {
    return {
      baseDate: this.$store.getters.baseDate,
      chartOptions: {},
    };
  },
  mounted() {
    this.baseDate = Vue.initBaseDate(this.baseDate);
    this.drawChart();
  },
  methods: {
    updateView() {
      this.baseDate = this.$store.getters.baseDate;
      this.drawChart();
    },
    drawChart() {
      let chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart);

      chart.paddingRight = 20;
      chart.dateFormatter.dateFormat = "yyyy-MM-dd";
      chart.dateFormatter.monthsShort = [
        "1월",
        "2월",
        "3월",
        "4월",
        "5월",
        "6월",
        "7월",
        "8월",
        "9월",
        "10월",
        "11월",
        "12월",
      ];
      let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.renderer.grid.template.location = 0;
      dateAxis.dateFormats.setKey("day", "MM월 dd일");
      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.tooltip.disabled = true;

      let series = chart.series.push(new am4charts.CandlestickSeries());
      series.dataFields.dateX = "date";
      series.dataFields.valueY = "close";
      series.dataFields.openValueY = "open";
      series.dataFields.lowValueY = "low";
      series.dataFields.highValueY = "high";
      series.dateFormatter.dateFormat = "yyyy-MM-dd";
      series.simplifiedProcessing = true;
      series.tooltipText =
        "Open:{openValueY.value}원\nLow:{lowValueY.value}원\nHigh:{highValueY.value}원\nClose:{valueY.value}원";

      chart.cursor = new am4charts.XYCursor();

      // a separate series for scrollbar
      let lineSeries = chart.series.push(new am4charts.LineSeries());
      lineSeries.dataFields.dateX = "date";
      lineSeries.dataFields.valueY = "close";
      // need to set on default state, as initially series is "show"
      lineSeries.defaultState.properties.visible = false;

      // hide from legend too (in case there is one)
      lineSeries.hiddenInLegend = true;
      lineSeries.fillOpacity = 0.5;
      lineSeries.strokeOpacity = 0.5;

      let scrollbarX = new am4charts.XYChartScrollbar();
      scrollbarX.series.push(lineSeries);
      chart.scrollbarX = scrollbarX;

      chart.data = this.getChartData();
      this.chart = chart;
    },
    generateBalanceData() {
      const groupByBankList = d3
        .nest()
        .key(function (data) {
          return data.bank_name;
        })
        .key(function (data) {
          return data.account_num;
        })
        .entries(this.$store.getters.requestItem.items);
      const rawOHLC = Vue.makeBalanceOHLCList(groupByBankList);
      const OHLC = d3
        .nest()
        .key(function (data) {
          return data.date;
        })
        .rollup(function (values) {
          return {
            open: d3.sum(values, function (data) {
              return data.open;
            }),
            high: d3.sum(values, function (data) {
              return data.high;
            }),
            low: d3.sum(values, function (data) {
              return data.low;
            }),
            close: d3.sum(values, function (data) {
              return data.close;
            }),
          };
        })
        .entries(rawOHLC);
      return OHLC;
    },
    getChartData() {
      const rawDataList = this.generateBalanceData();
      const result = [];
      rawDataList.forEach((element) => {
        result.push({
          date: element.key,
          open: element.value.open,
          high: element.value.high,
          low: element.value.low,
          close: element.value.close,
        });
      });
      return result;
    },
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
};
</script>
<style scoped>
.hello {
  width: 100%;
  height: 300px;
}
</style>
